import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import theme from "../../theme";
import CommonTable from "../table";
import { downloadFile, getCleanMonth, getIntentDisplay, moneyValue } from "../../utils";
import CommonButton from "../button";
import { getPaymentDownloadAction, resetPaymentDownloadAction, getPaymentWDListAction } from "../../containers/dashboard/action";

/*Not following configurations settings*/

const useStyles = makeStyles(() => ({
  salary: {
    color: theme.colors.Violet,
    fontSize: "14px",
    fontFamily: theme.fontFamily.MARKPROBOOK,
    "& .currency": {
      fontSize: "10px",
      position: "relative",
      bottom: "3px",
    },
  },
  withdrawAction: {
    display: "flex",
    alignItems: "center",
    "& img": {
      height: "19px",
      width: "19px",
      filter: "brightness(0) invert(0)",
      cursor: "pointer",
    },
  },
  headerName: {
    fontSize: "13px",
    fontFamily: theme.fontFamily.MARKPROHEAVY,
    opacity: "0.3",
    color: theme.colors.Violet,
  },
  srOnly: {
    position: "absolute",
    width: "1px",
    height: "1px",
    padding: 0,
    margin: "-1px",
    overflow: "hidden",
    clip: "rect(0, 0, 0, 0)",
    whiteSpace: "nowrap",
    borderWidth: 0
  }
}));

function TransactionsHistoryTable({
  id,
  setId,
  data,
  setTransactionHistoryFilters,
  transactionHistoryFilters,
  page,
  setPage,
  totalPage,
  paymentsPage,
  setPaymentsPage,
  totalPaymentsPages,
  SetTotalPaymentsPages,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");
  const [currenId, setCurrentId] = useState("");
  const [subTablePage, setSubTablePage] = useState(1)
  const [subTablePageId, setSubTablePageId] = useState({page: 1, currenId: ""})

  const dashboardReducer = useSelector((state) => state.dashboardReducer);
  const getPaymentDownloadMethod = useCallback(
    (company) => dispatch(getPaymentDownloadAction(company)),
    [dispatch]
  );

  const resetPaymentDownloadMethod = useCallback(
    () => dispatch(resetPaymentDownloadAction()),
    [dispatch]
  );

  const getPaymentWDListActionMethod = useCallback(
    (data) => dispatch(getPaymentWDListAction(data)),
    [dispatch]
  );

  useEffect(() => {
    if (dashboardReducer?.csv && dashboardReducer?.filename) {
      downloadFile(dashboardReducer.csv, dashboardReducer.filename);
      resetPaymentDownloadMethod();
    }
  }, [dashboardReducer, resetPaymentDownloadMethod]);
  useEffect(() => {
    
  }, [dispatch])

  useEffect(() => {
    if (id >= 0 && document.getElementById(`expand_icon_${id}`))
      document.getElementById(`expand_icon_${id}`).scrollIntoView();
  }, [id]);

  useEffect(() => {
    
  }, [searchTerm])
  useEffect(() => {
    if (transactionHistoryFilters?.search) {
      setSearchTerm(transactionHistoryFilters.search);
    }
  }, [transactionHistoryFilters]);



  const onSearchCancel = () => {
    if (transactionHistoryFilters?.search) {
      setTransactionHistoryFilters(current => {
        if (current) {
          const { search, ...rest } = current;
          return rest;
        }
      });
    }
  };
  return (
    <>
      <CommonTable
        columns={[
          {
            Header: "Company Name",
            accessor: "name",
            HeaderCell: (data) => (
              <div className={classes.headerName}>{data}</div>
            ),
          },
          {
            Header: "Employees with transactions",
            accessor: "total_transactions_employees",
            HeaderCell: (data) => (
              <div className={classes.headerName}>{data}</div>
            ),
            Cell: (data, row) => (
              <div className={classes.salary}>
                {data}
              </div>
            ),
          },
          {
            Header: "Total Net Salaries",
            accessor: "net_salaries",
            HeaderCell: (data) => (
              <div className={classes.headerName}>{data}</div>
            ),
            Cell: (data, row) => (
              <div className={classes.salary}>
                <span className="currency">{row?.currency}</span>{" "}
                {moneyValue(data)}
              </div>
            ),
          },
          {
            Header: "Total Withdrawn",
            accessor: "id",
            HeaderCell: (data) => (
              <div className={classes.headerName}>{data}</div>
            ),
            Cell: (data, row) => (
              <div className={classes.salary}>
                <span className="currency">{row?.currency}</span>
                {moneyValue(row?.withdrawals)}
              </div>
            ),
          },
          {
            Header: "Remaining Balance",
            accessor: "id",
            HeaderCell: (data) => (
              <div className={classes.headerName}>{data}</div>
            ),
            Cell: (data, row, index) => (
              <div
                className={classes.withdrawAction}
                style={{ justifyContent: "space-between" }}
              >
                <div
                  className={classes.salary}
                  style={{
                    fontFamily: theme.fontFamily.MARKPROHEAVY,
                  }}
                >
                  <span className="currency">{row?.currency}</span>{" "}
                  {moneyValue(row?.balance)}
                </div>

                <div>
                  <CommonButton
                    type="button"
                    text="Download"
                    stylevariant="primary"
                    onClick={() => { getPaymentDownloadMethod(row.id) }}
                    style={{ padding: "5px 15px" }}
                    fontSize="12px"
                    fontFamily={theme.fontFamily.MARKPROHEAVY}
                  />
                </div>

                <div>
                  <img
                    id={`expand_icon_${index}`}
                    src="/images/chevron_dropdown_date.png"
                    alt="chevron_dropdown_date"
                    onClick={(...data) => {
                      getPaymentWDListActionMethod({ company_id: row?.id, page: 1 });
                      setSubTablePageId({ page: subTablePageId?.page, currenId: row?.id })
                      setId((temp) => (temp === index ? null : index));
                      setPaymentsPage(1);
                      SetTotalPaymentsPages(1);
                    }}
                    style={{
                      transform: id === index ? "rotate(180deg)" : "",
                    }}
                  />
                </div>
                
              </div>
            ),
              
          },
        ]}
        data={data}
        showSubTableFor={id}
        subTableContent={(wrapper) => {
          return(
          <CommonTable
            search={{
              term: searchTerm,
              placeholder: "Search by name, email, intent or state",
              onChange: (e) => {
                setSearchTerm(e.target.value);
              },
              onSubmit: () =>
                setTransactionHistoryFilters({
                  ...transactionHistoryFilters,
                  search: searchTerm,
                }),
              onCancel: onSearchCancel,
            }}
            onRowClick={() => {
              setTransactionHistoryFilters({
                ...transactionHistoryFilters,
                history: true,
              })
            }}
            columns={[
              {
                Header: "Employee Name",
                HeaderCell: (data) => (
                  <div className={classes.headerName}>{data}</div>
                ),
                Cell: (data, row) => (
                  <div
                  // style={{
                  //   fontFamily: theme.fontFamily.MARKPROHEAVY,
                  //   flex: 0.5,
                  // }}
                  >
                    {row?.employee}
                  </div>
                ),
              },
              {
                Header: "Intent",
                accessor: "intent",
                HeaderCell: (data) => (
                  <div className={classes.headerName}>{data}</div>
                ),
                Cell: (data) => getIntentDisplay(data),
              },
              {
                Header: "State",
                HeaderCell: (data) => (
                  <div className={classes.headerName}>{data}</div>
                ),
                Cell: (data, row) => (
                  <div
                    style={{
                      fontFamily: theme.fontFamily.MARKPROHEAVY,
                      flex: 0.5,
                    }}
                  >
                    {row?.state}
                  </div>
                ),
              },
              {
                Header: "Created At",
                accessor: "id",
                HeaderCell: (data) => (
                  <div className={classes.headerName}>{data}</div>
                ),
                Cell: (data, row) => (
                  <div>
                    {row?.intent === "TRANSFER" ? (
                      <>
                        <span className={classes.srOnly}>
                          {new Date(row?.created_at).toLocaleDateString()}
                        </span>
                        <span>{getCleanMonth(new Date(row?.created_at))}</span>
                      </>
                    ) : (
                      new Date(row?.created_at).toLocaleDateString()
                    )}
                  </div>
                ),
              },
              {
                Header: "Settled At",
                HeaderCell: (data) => (
                  <div className={classes.headerName}>{data}</div>
                ),
                Cell: (data, row) => (
                  <div
                  // style={{
                  //   fontFamily: theme.fontFamily.MARKPROHEAVY,
                  //   flex: 0.5,
                  // }}
                  >
                    {row?.settled_at
                      ? new Date(row?.settled_at).toLocaleDateString()
                      : "----------"}
                  </div>
                ),
              },
              {
                Header: "Declined At",
                HeaderCell: (data) => (
                  <div className={classes.headerName}>{data}</div>
                ),
                Cell: (data, row) => (
                  <div
                  // style={{
                  //   fontFamily: theme.fontFamily.MARKPROHEAVY,
                  //   flex: 0.5,
                  // }}
                  >
                    {row?.declined_at
                      ? new Date(row?.declined_at).toLocaleDateString()
                      : "----------"}
                  </div>
                ),
              },
              {
                Header: "Net",
                HeaderCell: (data) => (
                  <div className={classes.headerName}>{data}</div>
                ),
                Cell: (data, row) => (
                  <div
                    className={classes.salary}
                    style={{
                      fontFamily: theme.fontFamily.MARKPROHEAVY,
                      flex: 0.5,
                    }}
                  >
                    <span className="currency">{wrapper?.currency}</span>
                    {moneyValue(row?.net)}
                  </div>
                ),
              },
              {
                Header: "Fees",
                HeaderCell: (data) => (
                  <div className={classes.headerName}>{data}</div>
                ),
                Cell: (data, row) => (
                  <div className={classes.salary}>
                    <span className="currency">{row?.company?.currency}</span>
                    {moneyValue(row?.fees)}
                  </div>
                ),
              },
              {
                Header: "Total",
                HeaderCell: (data) => (
                  <div className={classes.headerName}>{data}</div>
                ),
                Cell: (data, row) => (
                  <div
                    className={classes.salary}
                    style={{
                      fontFamily: theme.fontFamily.MARKPROHEAVY,
                      flex: 0.5,
                    }}
                  >
                    <span className="currency">{wrapper?.currency}</span>
                    {moneyValue(row?.total)}
                  </div>
                ),
              },
            ]}
            data={
              dashboardReducer?.paymentSubTableList?.data || []
            }
            subTable={true}
          />
        )}}
        subTableContentPagination={(parentId) => {
          return (dashboardReducer?.paymentSubTableList?.data?.length ? (
            <div className={"pagination"} style={{ marginBottom: "1rem" }}>
              <Pagination
                page={dashboardReducer?.paymentSubTableList.paymentSubTablePage}
                count={Math.ceil(dashboardReducer?.paymentSubTableList?.count / 20)}
                showFirstButton
                showLastButton
                onChange={(event, value) => {
                  getPaymentWDListActionMethod({ page: value, company_id: parentId })
                }}
              />
            </div>
          ) : null)
        }}
      />
      {data?.length ? (
        <div className={"pagination"}>
          <Pagination
            page={page}
            count={totalPage}
            showFirstButton
            showLastButton
            onChange={(event, value) => {
              setPage(value);

              setId(null);
            }}
          />
        </div>
      ) : null}
    </>
  );
}

export default TransactionsHistoryTable;
