import { put, takeLatest, call } from "redux-saga/effects";
import {
  PAYMENT_LIST_ACTION,
  PAYMENT_LIST_LOADING,
  PAYMENT_LIST_SUCCESS,
  PAYMENT_LIST_ERROR,
  GET_PAYMENT_ACTION,
  GET_PAYMENT_LOADING,
  GET_PAYMENT_SUCCESS,
  GET_PAYMENT_ERROR,
  MONTHS_TO_SETTLE_ACTION,
  MONTHS_TO_SETTLE_ERROR,
  MONTHS_TO_SETTLE_LOADING,
  MONTHS_TO_SETTLE_SUCCESS,
  BULK_MONTHS_TO_SETTLE_ACTION,
  BULK_MONTHS_TO_SETTLE_ERROR,
  BULK_MONTHS_TO_SETTLE_LOADING,
  BULK_MONTHS_TO_SETTLE_SUCCESS,
  SETTLE_PAYMENT_ACTION,
  SETTLE_PAYMENT_LOADING,
  SETTLE_PAYMENT_SUCCESS,
  SETTLE_PAYMENT_ERROR,
  SETTLE_SALARY_ACTION,
  SETTLE_SALARY_LOADING,
  SETTLE_SALARY_SUCCESS,
  SETTLE_SALARY_ERROR,
  BULK_SETTLE_SALARY_ACTION,
  BULK_SETTLE_SALARY_LOADING,
  BULK_SETTLE_SALARY_SUCCESS,
  BULK_SETTLE_SALARY_ERROR,
  COMPANY_LIST_ACTION,
  COMPANY_LIST_LOADING,
  COMPANY_LIST_SUCCESS,
  COMPANY_LIST_ERROR,
  GET_COMPANY_ACTION,
  GET_COMPANY_LOADING,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_ERROR,
  EMPLOYEE_LIST_ACTION,
  EMPLOYEE_LIST_LOADING,
  EMPLOYEE_LIST_SUCCESS,
  EMPLOYEE_LIST_ERROR,
  GET_EMPLOYEE_ACTION,
  GET_EMPLOYEE_LOADING,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_ERROR,
  OVERVIEW_ACTION,
  OVERVIEW_LOADING,
  OVERVIEW_SUCCESS,
  OVERVIEW_ERROR,
  INVITE_COMPANY_ACTION,
  INVITE_COMPANY_LOADING,
  INVITE_COMPANY_SUCCESS,
  INVITE_COMPANY_ERROR,
  UPDATE_COMPANY_ACTION,
  UPDATE_COMPANY_LOADING,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_ERROR,
  EXCHANGE_LIST_ACTION,
  EXCHANGE_LIST_LOADING,
  EXCHANGE_LIST_SUCCESS,
  EXCHANGE_LIST_ERROR,
  GET_EXCHANGE_ACTION,
  GET_EXCHANGE_LOADING,
  GET_EXCHANGE_SUCCESS,
  GET_EXCHANGE_ERROR,
  CREATE_EXCHANGE_ACTION,
  CREATE_EXCHANGE_LOADING,
  CREATE_EXCHANGE_SUCCESS,
  CREATE_EXCHANGE_ERROR,
  UPDATE_EXCHANGE_ACTION,
  UPDATE_EXCHANGE_LOADING,
  UPDATE_EXCHANGE_SUCCESS,
  UPDATE_EXCHANGE_ERROR,
  DELETE_EXCHANGE_ACTION,
  DELETE_EXCHANGE_LOADING,
  DELETE_EXCHANGE_SUCCESS,
  DELETE_EXCHANGE_ERROR,
  BACKOFFICE_DEDUCTION_LIST_ACTION,
  BACKOFFICE_DEDUCTION_LIST_LOADING,
  BACKOFFICE_DEDUCTION_LIST_SUCCESS,
  BACKOFFICE_DEDUCTION_LIST_ERROR,
  GET_DEDUCTION_ACTION,
  GET_DEDUCTION_LOADING,
  GET_DEDUCTION_SUCCESS,
  GET_DEDUCTION_ERROR,
  CREATE_DEDUCTION_ACTION,
  CREATE_DEDUCTION_LOADING,
  CREATE_DEDUCTION_SUCCESS,
  CREATE_DEDUCTION_ERROR,
  UPDATE_DEDUCTION_ACTION,
  UPDATE_DEDUCTION_LOADING,
  UPDATE_DEDUCTION_SUCCESS,
  UPDATE_DEDUCTION_ERROR,
  DELETE_DEDUCTION_ACTION,
  DELETE_DEDUCTION_LOADING,
  DELETE_DEDUCTION_SUCCESS,
  DELETE_DEDUCTION_ERROR,
  OVERVIEW_FILTER_ACTION,
  OVERVIEW_FILTER_SUCCESS,
  TRANSACTIONS_WITHDRAWAL_REQUESTS_FILTER_ACTION,
  TRANSACTIONS_WITHDRAWAL_REQUESTS_FILTER_SUCCESS,
  TRANSACTIONS_OVERTIME_REQUESTS_FILTER_ACTION,
  TRANSACTIONS_OVERTIME_REQUESTS_FILTER_SUCCESS,
  TRANSACTION_HISTORY_ACTION,
  TRANSACTION_HISTORY_LOADING,
  TRANSACTION_HISTORY_SUCCESS,
  TRANSACTION_HISTORY_ERROR,
  BACKOFFICE_COUNTRY_FEES_LIST_ACTION,
  BACKOFFICE_COUNTRY_FEES_LIST_LOADING,
  BACKOFFICE_COUNTRY_FEES_LIST_SUCCESS,
  BACKOFFICE_COUNTRY_FEES_LIST_ERROR,
  CREATE_COUNTRY_FEES_ACTION,
  CREATE_COUNTRY_FEES_LOADING,
  CREATE_COUNTRY_FEES_SUCCESS,
  CREATE_COUNTRY_FEES_ERROR,
  UPDATE_COUNTRY_FEES_ACTION,
  UPDATE_COUNTRY_FEES_LOADING,
  UPDATE_COUNTRY_FEES_SUCCESS,
  UPDATE_COUNTRY_FEES_ERROR,
  PAYMENT_DOWNLOAD_LOADING,
  PAYMENT_DOWNLOAD_ACTION,
  PAYMENT_DOWNLOAD_SUCCESS,
  PAYMENT_DOWNLOAD_ERROR, PAYMENT_DOWNLOAD_RESET_ACTION,
  PAYMENT_SUB_TABLE_LIST_ACTION,
  PAYMENT_SUB_TABLE_LIST_ERROR,
  PAYMENT_SUB_TABLE_LIST_SUCCESS,
  PAYMENT_SUB_TABLE_LIST_LOADING
} from "./types";
import Api from "./api";

function* getPaymentListSaga(data) {
  yield put({ type: PAYMENT_LIST_LOADING });
  try {
    let res = yield call(Api.getPaymentList, data.payload);
    if (res.message) {
      yield put({ type: PAYMENT_LIST_ERROR, payload: res.message });
    } else yield put({ type: PAYMENT_LIST_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: PAYMENT_LIST_ERROR, payload: error.message });
  }
}

function* getPaymentDownloadSaga(data) {
  yield put({ type: PAYMENT_DOWNLOAD_LOADING });
  try {
    let res = yield call(Api.getPaymentDownload, data);
    if (res.message) {
      yield put({ type: PAYMENT_LIST_ERROR, payload: res.message });
    } else yield put({ type: PAYMENT_DOWNLOAD_SUCCESS, payload: res, filename: `payment-history-${data.company_id}.csv` });
  } catch (error) {
    yield put({ type: PAYMENT_DOWNLOAD_ERROR, payload: error.message });
  }
}

function* getPaymentWDSaga(data) {
  yield put({ type: PAYMENT_SUB_TABLE_LIST_LOADING });
  try {
    let res = yield call(Api.getPaymentWDList, {
      company_id: data?.payload?.company_id,
      page_size: 20,
      page: data?.payload?.page
    });
    if (res.message) {
      yield put({
        type: PAYMENT_SUB_TABLE_LIST_ERROR, payload: res.message
      });
    } else yield put({
      type: PAYMENT_SUB_TABLE_LIST_SUCCESS, payload: {
        ...res, paymentSubTablePage: data?.payload?.page
      }
    });
  } catch (error) {
    yield put({ type: PAYMENT_DOWNLOAD_ERROR, payload: error.message });
  }
}

function* resetPaymentDownloadSaga() {
  yield put({ type: PAYMENT_DOWNLOAD_SUCCESS, payload: null, filename: null });
}

function* getPaymentInfoSaga(data) {
  yield put({ type: GET_PAYMENT_LOADING });
  try {
    let res = yield call(Api.getPaymentInfo, data.payment_id);
    if (res.message) {
      yield put({ type: GET_PAYMENT_ERROR, payload: res.message });
    } else yield put({ type: GET_PAYMENT_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: GET_PAYMENT_ERROR, payload: error.message });
  }
}

function* settlePaymentSaga(data) {
  yield put({ type: SETTLE_PAYMENT_LOADING });
  try {
    let res = yield call(Api.settlePayment, data.payload, data.payment_id);
    if (res.message) {
      yield put({ type: SETTLE_PAYMENT_ERROR, payload: res.message });
    } else yield put({ type: SETTLE_PAYMENT_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: SETTLE_PAYMENT_ERROR, payload: error.message });
  }
}

function* getMonthsToSettleSaga(data) {
  yield put({ type: MONTHS_TO_SETTLE_LOADING });
  try {
    let res = yield call(Api.getMonthsToSettle, data.employee_id);
    if (res.message) {
      yield put({ type: MONTHS_TO_SETTLE_ERROR, payload: res.message });
    } else yield put({ type: MONTHS_TO_SETTLE_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: MONTHS_TO_SETTLE_ERROR, payload: error.message });
  }
}

function* getBulkMonthsToSettleSaga(data) {
  yield put({ type: BULK_MONTHS_TO_SETTLE_LOADING });
  try {
    let res = yield call(Api.getBulkMonthsToSettle, data.country);
    if (res.message) {
      yield put({ type: BULK_MONTHS_TO_SETTLE_ERROR, payload: res.message });
    } else yield put({ type: BULK_MONTHS_TO_SETTLE_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: BULK_MONTHS_TO_SETTLE_ERROR, payload: error.message });
  }
}

function* settleSalarySaga(data) {
  yield put({ type: SETTLE_SALARY_LOADING });
  try {
    let res = yield call(Api.settleSalary, data.employee_id, data.month);
    if (res.message) {
      yield put({ type: SETTLE_SALARY_ERROR, payload: res.message });
    } else yield put({ type: SETTLE_SALARY_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: SETTLE_SALARY_ERROR, payload: error.message });
  }
}

function* bulkSettleSalarySaga(data) {
  yield put({ type: BULK_SETTLE_SALARY_LOADING });
  try {
    let res = yield call(Api.bulkSettleSalary, data.employees_ids, data.month);
    if (res.message) {
      yield put({ type: BULK_SETTLE_SALARY_ERROR, payload: res.message });
    } else yield put({ type: BULK_SETTLE_SALARY_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: BULK_SETTLE_SALARY_ERROR, payload: error.message });
  }
}

function* getCompanyListSaga(data) {
  yield put({ type: COMPANY_LIST_LOADING });
  try {
    let res = yield call(Api.getCompanyList, data.payload);
    if (res.message) {
      yield put({ type: COMPANY_LIST_ERROR, payload: res.message });
    } else yield put({ type: COMPANY_LIST_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: COMPANY_LIST_ERROR, payload: error.message });
  }
}

function* getCompanyInfoSaga(data) {
  yield put({ type: GET_COMPANY_LOADING });
  try {
    let res = yield call(Api.getCompanyInfo, data.company_id);
    if (res.message) {
      yield put({ type: GET_COMPANY_ERROR, payload: res.message });
    } else yield put({ type: GET_COMPANY_SUCCESS, payload: res.data });
  } catch (error) {
    yield put({ type: GET_COMPANY_ERROR, payload: error.message });
  }
}

function* getEmployeeListSaga(data) {
  yield put({ type: EMPLOYEE_LIST_LOADING });
  try {
    let res = yield call(Api.getEmployeeList, data.payload);
    if (res.message) {
      yield put({ type: EMPLOYEE_LIST_ERROR, payload: res.message });
    } else yield put({ type: EMPLOYEE_LIST_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: EMPLOYEE_LIST_ERROR, payload: error.message });
  }
}

function* getEmployeeInfoSaga(data) {
  yield put({ type: GET_EMPLOYEE_LOADING });
  try {
    let res = yield call(Api.getEmployeeInfo, data.employee_id);
    if (res.message) {
      yield put({ type: GET_EMPLOYEE_ERROR, payload: res.message });
    } else yield put({ type: GET_EMPLOYEE_SUCCESS, payload: res.data });
  } catch (error) {
    yield put({ type: GET_EMPLOYEE_ERROR, payload: error.message });
  }
}

function* getOverviewSaga(data) {
  yield put({ type: OVERVIEW_LOADING });
  try {
    let res = yield call(Api.getOverview, data.payload);
    if (res.message) {
      yield put({ type: OVERVIEW_ERROR, payload: res.message });
    } else yield put({ type: OVERVIEW_SUCCESS, payload: res.data });
  } catch (error) {
    yield put({ type: OVERVIEW_ERROR, payload: error.message });
  }
}

function* inviteCompanySaga(data) {
  yield put({ type: INVITE_COMPANY_LOADING });
  try {
    let res = yield call(Api.inviteCompany, data.payload);
    if (res.message) {
      yield put({ type: INVITE_COMPANY_ERROR, payload: res.message });
    } else yield put({ type: INVITE_COMPANY_SUCCESS, payload: res.data });
  } catch (error) {
    yield put({ type: INVITE_COMPANY_ERROR, payload: error.message });
  }
}

function* updateCompanySaga(data) {
  yield put({ type: UPDATE_COMPANY_LOADING });
  try {
    let res = yield call(Api.updateCompany, data.payload);
    if (res.message) {
      yield put({ type: UPDATE_COMPANY_ERROR, payload: res.message });
    } else yield put({ type: UPDATE_COMPANY_SUCCESS, payload: res.data });
  } catch (error) {
    yield put({ type: UPDATE_COMPANY_ERROR, payload: error.message });
  }
}

function* getExchangeListSaga(data) {
  yield put({ type: EXCHANGE_LIST_LOADING });
  try {
    let res = yield call(Api.getExchangeList, data.payload);
    if (res.message) {
      yield put({ type: EXCHANGE_LIST_ERROR, payload: res.message });
    } else yield put({ type: EXCHANGE_LIST_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: EXCHANGE_LIST_ERROR, payload: error.message });
  }
}

function* getExchangeInfoSaga(data) {
  yield put({ type: GET_EXCHANGE_LOADING });
  try {
    let res = yield call(Api.getExchangeInfo, data.exchange_id);
    if (res.message) {
      yield put({ type: GET_EXCHANGE_ERROR, payload: res.message });
    } else yield put({ type: GET_EXCHANGE_SUCCESS, payload: res.data });
  } catch (error) {
    yield put({ type: GET_EXCHANGE_ERROR, payload: error.message });
  }
}

function* createExchangeSaga(data) {
  yield put({ type: CREATE_EXCHANGE_LOADING });
  try {
    let res = yield call(Api.createExchange, data.payload);
    if (res.message) {
      yield put({ type: CREATE_EXCHANGE_ERROR, payload: res.message });
    } else yield put({ type: CREATE_EXCHANGE_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: CREATE_EXCHANGE_ERROR, payload: error.message });
  }
}

function* updateExchangeSaga(data) {
  yield put({ type: UPDATE_EXCHANGE_LOADING });
  try {
    let res = yield call(Api.updateExchange, data.payload, data.exchange_id);
    if (res.message) {
      yield put({ type: UPDATE_EXCHANGE_ERROR, payload: res.message });
    } else yield put({ type: UPDATE_EXCHANGE_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: UPDATE_EXCHANGE_ERROR, payload: error.message });
  }
}

function* deleteExchangeSaga(data) {
  yield put({ type: DELETE_EXCHANGE_LOADING });
  try {
    let res = yield call(Api.deleteExchange, data.exchange_id);
    if (res.message) {
      yield put({ type: DELETE_EXCHANGE_ERROR, payload: res.message });
    } else yield put({ type: DELETE_EXCHANGE_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: DELETE_EXCHANGE_ERROR, payload: error.message });
  }
}

function* getDeductionListSaga(data) {
  yield put({ type: BACKOFFICE_DEDUCTION_LIST_LOADING });
  try {
    let res = yield call(Api.getDeductionList, data.payload);
    if (res.message) {
      yield put({
        type: BACKOFFICE_DEDUCTION_LIST_ERROR,
        payload: res.message,
      });
    } else yield put({ type: BACKOFFICE_DEDUCTION_LIST_SUCCESS, payload: res });
  } catch (error) {
    yield put({
      type: BACKOFFICE_DEDUCTION_LIST_ERROR,
      payload: error.message,
    });
  }
}

function* getDeductionInfoSaga(data) {
  yield put({ type: GET_DEDUCTION_LOADING });
  try {
    let res = yield call(Api.getDeductionInfo, data.deduction_id);
    if (res.message) {
      yield put({ type: GET_DEDUCTION_ERROR, payload: res.message });
    } else yield put({ type: GET_DEDUCTION_SUCCESS, payload: res.data });
  } catch (error) {
    yield put({ type: GET_DEDUCTION_ERROR, payload: error.message });
  }
}

function* createDeductionSaga(data) {
  yield put({ type: CREATE_DEDUCTION_LOADING });
  try {
    let res = yield call(Api.createDeduction, data.payload);
    if (res.message) {
      yield put({ type: CREATE_DEDUCTION_ERROR, payload: res.message });
    } else yield put({ type: CREATE_DEDUCTION_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: CREATE_DEDUCTION_ERROR, payload: error.message });
  }
}

function* updateDeductionSaga(data) {
  yield put({ type: UPDATE_DEDUCTION_LOADING });
  try {
    let res = yield call(Api.updateDeduction, data.payload, data.deduction_id);
    if (res.message) {
      yield put({ type: UPDATE_DEDUCTION_ERROR, payload: res.message });
    } else yield put({ type: UPDATE_DEDUCTION_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: UPDATE_DEDUCTION_ERROR, payload: error.message });
  }
}

function* deleteDeductionSaga(data) {
  yield put({ type: DELETE_DEDUCTION_LOADING });
  try {
    let res = yield call(Api.deleteDeduction, data.deduction_id);
    if (res.message) {
      yield put({ type: DELETE_DEDUCTION_ERROR, payload: res.message });
    } else yield put({ type: DELETE_DEDUCTION_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: DELETE_DEDUCTION_ERROR, payload: error.message });
  }
}

function* setOverviewFilters(data) {
  yield put({ type: OVERVIEW_FILTER_SUCCESS, payload: data.payload });
}

function* setWithdrawalRequestsFilters(data) {
  yield put({
    type: TRANSACTIONS_WITHDRAWAL_REQUESTS_FILTER_SUCCESS,
    payload: data.payload,
  });
}

function* setOvertimeRequestsFilters(data) {
  yield put({
    type: TRANSACTIONS_OVERTIME_REQUESTS_FILTER_SUCCESS,
    payload: data.payload,
  });
}

function* transactionHistorySaga(data) {
  yield put({ type: TRANSACTION_HISTORY_LOADING });
  try {
    let res = yield call(Api.getTransactionHistory, data.payload);
    if (res.message) {
      yield put({ type: TRANSACTION_HISTORY_ERROR, payload: res.message });
    } else yield put({ type: TRANSACTION_HISTORY_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: TRANSACTION_HISTORY_ERROR, payload: error.message });
  }
}

function* getCountryListSaga(data) {
  yield put({ type: BACKOFFICE_COUNTRY_FEES_LIST_LOADING });
  try {
    let res = yield call(Api.getFeesList, data.payload);
    if (res.message) {
      yield put({
        type: BACKOFFICE_COUNTRY_FEES_LIST_ERROR,
        payload: res.message,
      });
    } else
      yield put({
        type: BACKOFFICE_COUNTRY_FEES_LIST_SUCCESS,
        payload: res,
      });
  } catch (error) {
    yield put({
      type: BACKOFFICE_COUNTRY_FEES_LIST_ERROR,
      payload: error.message,
    });
  }
}

function* createCountryFeesSaga(data) {
  yield put({ type: CREATE_COUNTRY_FEES_LOADING });
  try {
    let res = yield call(Api.createFees, data.payload);
    if (res.message) {
      yield put({ type: CREATE_COUNTRY_FEES_ERROR, payload: res.message });
    } else yield put({ type: CREATE_COUNTRY_FEES_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: CREATE_COUNTRY_FEES_ERROR, payload: error.message });
  }
}

function* updateCountryFeesSaga(data) {
  yield put({ type: UPDATE_COUNTRY_FEES_LOADING });
  try {
    let res = yield call(Api.updateFees, data.payload);
    if (res.message) {
      yield put({ type: UPDATE_COUNTRY_FEES_ERROR, payload: res.message });
    } else yield put({ type: UPDATE_COUNTRY_FEES_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: UPDATE_COUNTRY_FEES_ERROR, payload: error.message });
  }
}

function* watchDashboardAsync() {
  yield takeLatest(PAYMENT_LIST_ACTION, getPaymentListSaga);
  yield takeLatest(PAYMENT_DOWNLOAD_ACTION, getPaymentDownloadSaga);
  yield takeLatest(PAYMENT_DOWNLOAD_RESET_ACTION, resetPaymentDownloadSaga);
  yield takeLatest(GET_PAYMENT_ACTION, getPaymentInfoSaga);
  yield takeLatest(SETTLE_PAYMENT_ACTION, settlePaymentSaga);
  yield takeLatest(MONTHS_TO_SETTLE_ACTION, getMonthsToSettleSaga);
  yield takeLatest(BULK_MONTHS_TO_SETTLE_ACTION, getBulkMonthsToSettleSaga);
  yield takeLatest(SETTLE_SALARY_ACTION, settleSalarySaga);
  yield takeLatest(BULK_SETTLE_SALARY_ACTION, bulkSettleSalarySaga);
  yield takeLatest(COMPANY_LIST_ACTION, getCompanyListSaga);
  yield takeLatest(GET_COMPANY_ACTION, getCompanyInfoSaga);
  yield takeLatest(EMPLOYEE_LIST_ACTION, getEmployeeListSaga);
  yield takeLatest(GET_EMPLOYEE_ACTION, getEmployeeInfoSaga);
  yield takeLatest(OVERVIEW_ACTION, getOverviewSaga);
  yield takeLatest(INVITE_COMPANY_ACTION, inviteCompanySaga);
  yield takeLatest(UPDATE_COMPANY_ACTION, updateCompanySaga);
  yield takeLatest(EXCHANGE_LIST_ACTION, getExchangeListSaga);
  yield takeLatest(GET_EXCHANGE_ACTION, getExchangeInfoSaga);
  yield takeLatest(CREATE_EXCHANGE_ACTION, createExchangeSaga);
  yield takeLatest(UPDATE_EXCHANGE_ACTION, updateExchangeSaga);
  yield takeLatest(DELETE_EXCHANGE_ACTION, deleteExchangeSaga);
  yield takeLatest(BACKOFFICE_DEDUCTION_LIST_ACTION, getDeductionListSaga);
  yield takeLatest(GET_DEDUCTION_ACTION, getDeductionInfoSaga);
  yield takeLatest(CREATE_DEDUCTION_ACTION, createDeductionSaga);
  yield takeLatest(UPDATE_DEDUCTION_ACTION, updateDeductionSaga);
  yield takeLatest(DELETE_DEDUCTION_ACTION, deleteDeductionSaga);
  yield takeLatest(OVERVIEW_FILTER_ACTION, setOverviewFilters);
  yield takeLatest(
    TRANSACTIONS_WITHDRAWAL_REQUESTS_FILTER_ACTION,
    setWithdrawalRequestsFilters
  );
  yield takeLatest(
    TRANSACTIONS_OVERTIME_REQUESTS_FILTER_ACTION,
    setOvertimeRequestsFilters
  );
  yield takeLatest(TRANSACTION_HISTORY_ACTION, transactionHistorySaga);
  yield takeLatest(BACKOFFICE_COUNTRY_FEES_LIST_ACTION, getCountryListSaga);
  yield takeLatest(CREATE_COUNTRY_FEES_ACTION, createCountryFeesSaga);
  yield takeLatest(UPDATE_COUNTRY_FEES_ACTION, updateCountryFeesSaga);
  yield takeLatest(PAYMENT_SUB_TABLE_LIST_ACTION, getPaymentWDSaga)
}

export default watchDashboardAsync;
