import React, {useCallback, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import theme from "../../theme";
import CommonTable from "../table";
import CommonButton from "../button";
import {moneyValue} from "../../utils";
import {getPaymentListAction, getEmployeeListAction} from "../../containers/dashboard/action";
import {useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles(() => ({
  salary: {
    color: theme.colors.Violet,
    fontSize: "14px",
    fontFamily: theme.fontFamily.MARKPROBOOK,
    "& .currency": {
      fontSize: "10px",
      position: "relative",
      bottom: "3px",
    },
  },
  withdrawAction: {
    display: "flex",
    alignItems: "center",
  },
  headerName: {
    fontSize: "13px",
    fontFamily: theme.fontFamily.MARKPROHEAVY,
    opacity: "0.3",
    color: theme.colors.Violet,
  },
}));

function WithdrawRequestsTable({
                                 id,
                                 setId,
                                 data,
                                 onConfirmWithdrawClick,
                                 withdrawPage,
                                 setWithdrawPage,
                                 setTotalWithdrawPages,
                                 totalWithdrawPages,
                                 companyPage,
                                 totalSalariesTransfersPage,
                                 setCompanyPage,
                                 setSelectedCompany,
                               }) {
  const classes = useStyles();
  const dashboardReducer = useSelector((state) => {
    return state.dashboardReducer;
  });

  return (
    <>
      <CommonTable
        columns={[
          {
            Header: "Company Name",
            accessor: "name",
            HeaderCell: (data) => {
              return <div className={classes.headerName}>{data}</div>;
            },
          },
          {
            Header: "Employees with requests",
            accessor: "total_withdrawal_employees",
            HeaderCell: (data) => {
              return <div className={classes.headerName}></div>;
            },
            Cell: (data, row, index) => {
              return (
                <div
                  className={classes.withdrawAction}
                  style={{justifyContent: "space-between"}}
                >
                  <div
                    className={classes.salary}
                    style={{
                      fontFamily: theme.fontFamily.MARKPROHEAVY,
                    }}
                  >
                    {/*{moneyValue(data)}*/}
                  </div>

                  <div>
                    <img
                      id={`expand_icon_${index}`}
                      src="/images/chevron_dropdown_date.png"
                      alt="chevron_dropdown_date"
                      onClick={() => {
                        setSelectedCompany({value: row.id, label: row.name})
                        setId((temp) => (temp === index ? null : index));
                        setWithdrawPage(1);
                        setTotalWithdrawPages(1);
                      }}
                      style={{
                        transform: id === index ? "rotate(180deg)" : "",
                      }}
                    ></img>
                  </div>
                </div>
              );
            },
          },
        ]}
        data={data}
        showSubTableFor={id}
        subTableContent={() => (
          <CommonTable
            subTable={true}
            columns={[
              {
                Header: "Employee Name",
                accessor: "employee",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data, record) => {
                  return (
                    <div>
                      {record.first_name}{" "}{record.last_name}
                      <span className="inactive-label">
                    {/* {data?.is_active ? "" : " (Inactive)"} */}
                  </span>
                    </div>
                  );
                },
              },
              {
                Header: "Request Date",
                accessor: "created_at",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data) => {
                  return moment(data).format("DD/MM/YYYY");
                },
              },
              {
                Header: "Amount",
                accessor: "amount",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data, row) => {
                  return (
                    <div className={classes.salary}>
                      <span className="currency">{row?.company?.currency}</span>{row?.profile?.gross_salary}.00
                      {/* {moneyValue(data)} */}
                    </div>
                  );
                },
              },
              {
                Header: "Fees",
                accessor: "fees",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data, row) => {
                  return (
                    <div className={classes.salary}>
                      <span className="currency">{row?.company?.currency}</span>{row?.profile?.deductions}
                      {/* {moneyValue(data)} */}
                    </div>
                  );
                },
              },
              {
                Header: "Net",
                accessor: "net",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data, row) => {
                  console.log(row);
                  return (
                    <div className={classes.salary}>
                      <span className="currency">{row?.company?.currency}</span>{row?.profile?.balance}
                      {/* {moneyValue(data)} */}
                    </div>
                  );
                },
              },
              {
                Header: "Withdraw Amount",
                accessor: "total",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data, row) => {
                  return (
                    <div className={classes.withdrawAction}>
                      <div
                        className={classes.salary}
                        style={{
                          fontFamily: theme.fontFamily.MARKPROHEAVY,
                          flex: 0.5,
                          marginRight: "10px",
                        }}
                      >
                        <span className="currency">{row?.company?.currency}</span>{row?.profile?.withdrawals}
                        {/* {moneyValue(data)} */}
                      </div>
                      {/* <CommonButton
                        type="button"
                        text="Confirm Transfer"
                        stylevariant="secondary3"
                        disabled={!row?.employee?.is_active}
                        onClick={() => {
                          onConfirmWithdrawClick(row.id);
                        }}
                        style={{flex: 0.5, height: "28px"}}
                        fontSize="10px"
                        fontFamily={theme.fontFamily.MARKPROBOLD}
                      /> */}
                    </div>
                  );
                },
              },
            ]}
            data={dashboardReducer?.employeeList?.data ? dashboardReducer.employeeList.data : []}
          />
        )}
        subTableContentPagination={() =>
          data?.length ? (
            <div className={"pagination"}>
              <Pagination
                page={withdrawPage}
                count={totalWithdrawPages}
                showFirstButton
                showLastButton
                onChange={(event, value) => {
                  setWithdrawPage(value);
                }}
              />
            </div>
          ) : null
        }
      />
      {data?.length ? (
        <div className={"pagination"}>
          <Pagination
            page={companyPage}
            count={totalSalariesTransfersPage}
            showFirstButton
            showLastButton
            onChange={(event, value) => {
              setCompanyPage(value);
              setId(null);
            }}
          />
        </div>
      ) : null}

    </>
  );
}

export default WithdrawRequestsTable;
