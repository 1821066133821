import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import reducers from "./reducers";
import { rootSaga } from "./rootSaga";
import reduxReset from "redux-reset";
import { composeWithDevTools } from 'redux-devtools-extension'

const sagaMiddleware = createSagaMiddleware();

const DEVELOPMENT = process.env.NODE_ENV !== 'production';

const configureStore = (initialState) => {
  const enhancer = compose(
    applyMiddleware(thunkMiddleware, sagaMiddleware),
    reduxReset()
  );
  const devTools = composeWithDevTools({
    trace: true
  });
  return createStore(
    reducers,
    initialState,
    DEVELOPMENT ? devTools(enhancer) : enhancer 
  );
};

const store = configureStore({});
sagaMiddleware.run(rootSaga);
export default store;
