import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import theme from "../../theme";

const useStyles = makeStyles(() => ({
  root: {},
  flexContainer: { display: "flex", alignItems: "center" },
}));

const CustomButton = withStyles({
  root: {
    backgroundColor: ({ stylevariant }) =>
      stylevariant
        ? stylevariant === "primary"
          ? theme.colors.DodgerBlue
          : stylevariant === "secondary1"
          ? theme.colors.Violet
          : stylevariant === "secondary2"
          ? theme.colors.White
          : stylevariant === "secondary3"
          ? theme.colors.MintGreen
          : stylevariant === "danger"
          ? theme.colors.Monza
          : stylevariant === "secondary4"
          ? theme.colors.DodgerBlue
          : "auto"
        : "auto",
    color: ({ stylevariant }) =>
      stylevariant
        ? stylevariant === "primary"
          ? theme.colors.White
          : stylevariant === "secondary1"
          ? theme.colors.MintGreenLight
          : stylevariant === "secondary2"
          ? theme.colors.DodgerBlue
          : stylevariant === "secondary3"
          ? theme.colors.Violet
          : stylevariant === "danger"
          ? theme.colors.White
          : stylevariant === "secondary4"
          ? theme.colors.White
          : "auto"
        : "auto",
    border: ({ stylevariant }) =>
      stylevariant
        ? stylevariant === "primary"
          ? `1px solid ${theme.colors.DodgerBlue}`
          : stylevariant === "secondary1"
          ? `1px solid ${theme.colors.Violet}`
          : stylevariant === "secondary2"
          ? `1px solid ${theme.colors.DodgerBlue}`
          : stylevariant === "secondary3"
          ? `1px solid ${theme.colors.MintGreen}`
          : stylevariant === "danger"
          ? `1px solid ${theme.colors.Monza}`
          : "auto"
        : "auto",
    borderRadius: "21px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: ({ stylevariant }) =>
        stylevariant
          ? stylevariant === "primary"
            ? theme.colors.Zumthor
            : stylevariant === "secondary1"
            ? theme.colors.MintGreen
            : stylevariant === "secondary2"
            ? theme.colors.Zumthor
            : stylevariant === "secondary3"
            ? theme.colors.Violet
            : stylevariant === "danger"
            ? theme.colors.Zumthor
            : "auto"
          : "auto",
      color: ({ stylevariant }) =>
        stylevariant
          ? stylevariant === "primary"
            ? theme.colors.Violet
            : stylevariant === "secondary1"
            ? theme.colors.Violet
            : stylevariant === "secondary2"
            ? theme.colors.DodgerBlue
            : stylevariant === "secondary3"
            ? theme.colors.MintGreenLight
            : stylevariant === "danger"
            ? theme.colors.Monza
            : stylevariant === "secondary4"
            ? theme.colors.Violet
            : "auto"
          : "auto",
    },
    "&:disabled": {
      backgroundColor: ({ stylevariant }) =>
        stylevariant
          ? stylevariant === "primary"
            ? theme.colors.Periwinkle
            : stylevariant === "secondary1"
            ? theme.colors.Violet
            : stylevariant === "secondary2"
            ? theme.colors.White
            : stylevariant === "secondary3"
            ? theme.colors.Violet
            : "auto"
          : "auto",
      color: ({ stylevariant }) =>
        stylevariant
          ? stylevariant === "primary"
            ? theme.colors.White
            : stylevariant === "secondary1"
            ? theme.colors.White
            : stylevariant === "secondary2"
            ? theme.colors.DodgerBlue
            : stylevariant === "secondary3"
            ? theme.colors.White
            : "auto"
          : "auto",
      opacity: "0.3",
    },
  },
})(Button);

function CommonButton({ text, loading, icon, fontSize, fontFamily, ...props }) {
  const classes = useStyles(props);

  return (
    <span className={classes.root}>
      <CustomButton variant="contained" fullWidth {...props}>
        {loading ? (
          <CircularProgress size={25} />
        ) : icon ? (
          <span className={classes.flexContainer}>
            <img
              src={icon}
              alt="icon"
              style={{ marginRight: "8px", marginBottom: "3px" }}
            />
            <span
              style={{
                fontSize: fontSize ? fontSize : "16px",
                fontFamily: fontFamily
                  ? fontFamily
                  : theme.fontFamily.MARKPROMEDIUM,
              }}
            >
              {text}
            </span>
          </span>
        ) : (
          <span
            style={{
              fontSize: fontSize ? fontSize : "16px",
              fontFamily: fontFamily
                ? fontFamily
                : theme.fontFamily.MARKPROMEDIUM,
            }}
          >
            {text}
          </span>
        )}
      </CustomButton>
    </span>
  );
}

export default CommonButton;
