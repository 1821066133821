import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Collapse, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,} from "@material-ui/core";
import theme from "../../theme";
import CommonButton from "../button";

const useStyles = makeStyles((props) => ({
  tableContainer: {
    background: theme.colors.White,
    boxShadow: (props) => (props.subTable ? "none" : "0px 2px 15px #D0CFDB99"),
    borderRadius: (props) =>
      props.subTable
        ? "none"
        : props.fullRadius
          ? "15px"
          : "0px 10px 10px 10px",
  },
  headerCell: {
    color: theme.colors.DodgerBlue,
    fontSize: "13px",
    fontFamily: theme.fontFamily.MARKPROBOLD,
    borderBottom: "1px solid #F6FAFF",
    backgroundColor: (props) => (props.subTable ? "#F9FBFF" : "auto"),
  },
  columnCell: {
    color: theme.colors.Violet,
    fontSize: "14px",
    fontFamily: theme.fontFamily.MARKPROBOOK,
    borderBottom: "1px solid #F6FAFF",
    backgroundColor: (props) => (props.subTable ? "#F9FBFF" : "auto"),
  },
  tableRow: {
    "&:hover": {backgroundColor: "#ECF3FF"},
  },
  noDataContainer: {
    height: "200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.colors.Violet,
    fontSize: "20px",
    fontFamily: theme.fontFamily.MARKPROBOOK,
  },
}));

function CommonTable({
                       columns,
                       data,
                       showSubTableFor,
                       subTableContent,
                       subTableContentPagination,
                       onRowClick,
                       search,
                       ...props
                     }) {
  const classes = useStyles(props);

  return (
    <TableContainer className={classes.tableContainer}>
      {
        search &&
        <div style={{
          padding: 16,
          background: "#ECF3FF",
          color: "#3E83FA",
          position: "relative",
        }}>
          <input
            style={{
              width: "85%",
              height: "24px",
              background: "transparent",
              fontSize: "16px",
              border: 0,
            }}
            value={search.term}
            onChange={e => search.onChange(e)}
            placeholder={search.placeholder || 'Search results...'}
            type="text"
          />
          <div
            style={{display: "flex", position: "absolute", right: "16px", top: "12px"}}>
            {search.term &&
              <CommonButton
                text="X"
                fontSize="12px"
                stylevariant="secondary2"
                onClick={() => search.onCancel()}
                style={{width: "10px", background: "transparent"}}
              />
            }
            <CommonButton
              type="button"
              stylevariant="secondary1"
              text="Submit"
              fontSize="12px"
              style={{width: "10px", marginLeft: "5px"}}
              disabled={!search.term}
              onClick={(e) => search.onSubmit(e)}
            />
          </div>
        </div>
      }
      <Table>
        <TableHead>
          <TableRow>
            {columns && columns.length
              ? columns.map((column, index) => {
                return (
                  <TableCell
                    key={index}
                    width={column.width ? column.width : "auto"}
                    className={classes.headerCell}
                  >
                    {column.HeaderCell
                      ? column.HeaderCell(column.Header)
                      : column.Header}
                  </TableCell>
                );
              })
              : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.length ? (
            data.map((each, rowIndex) => {
              return (
                <React.Fragment key={rowIndex}>
                  <TableRow
                    className={classes.tableRow}
                    style={{cursor: onRowClick ? "pointer" : "auto"}}
                    onClick={() => {
                      if (onRowClick) {
                        onRowClick(rowIndex);
                      }
                    }}
                  >
                    {columns && columns.length
                      ? columns.map((column, colIndex) => {
                        return (
                          <TableCell
                            key={colIndex}
                            className={classes.columnCell}
                          >
                            {column.Cell
                              ? column.Cell(
                                each[column.accessor],
                                each,
                                rowIndex
                              )
                              : each[column.accessor]}
                          </TableCell>
                        );
                      })
                      : null}
                  </TableRow>
                  {subTableContent ? (
                    <TableRow>
                      <TableCell
                        style={{padding: 0}}
                        colSpan={columns && columns.length}
                      >
                        <Collapse
                          in={showSubTableFor === rowIndex}
                          timeout="auto"
                          unmountOnExit
                        >
                          {subTableContent(each)}
                          {subTableContentPagination
                            ? subTableContentPagination(each?.id)
                            : null}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  ) : null}
                </React.Fragment>
              );
            })
          ) : (
            <TableRow>
              <TableCell
                style={{padding: 0}}
                colSpan={columns && columns.length}
              >
                <div className={classes.noDataContainer}>No data found</div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CommonTable;
