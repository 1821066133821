import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import theme from "../../theme";
import CommonTable from "../table";
import CommonButton from "../button";
import { moneyValue } from "../../utils";

const useStyles = makeStyles(() => ({
  salary: {
    color: theme.colors.Violet,
    fontSize: "14px",
    fontFamily: theme.fontFamily.MARKPROBOOK,
    "& .currency": {
      fontSize: "10px",
      position: "relative",
      bottom: "3px",
    },
  },
  withdrawAction: {
    display: "flex",
    alignItems: "center",
    "& img": {
      height: "19px",
      width: "19px",
      filter: "brightness(0) invert(0)",
      cursor: "pointer",
    },
  },
  headerName: {
    fontSize: "13px",
    fontFamily: theme.fontFamily.MARKPROHEAVY,
    opacity: "0.3",
    color: theme.colors.Violet,
  },
}));

function SalariesTransfersTable({
  id,
  setId,
  onTransferCLick,
  onBulkTransferCLick,
  data,
  page,
  setPage,
  totalPage,
  employeesPage,
  employeesSetPage,
  employeesTotalPage,
  employeesSetTotalPage,
}) {
  const classes = useStyles();
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const dashboardReducer = useSelector((state) => {
    return state.dashboardReducer;
  });

  const isEmployeeChecked = (employee) => {
    return selectedEmployees.indexOf(employee) > -1
  }

  const isAllEmployeeChecked = () => {
    const employees_list = dashboardReducer?.employeeList?.data || [];
    const possibleCount = employees_list.filter((obj) => obj.is_active).length;

    return (selectedEmployees?.length) && (selectedEmployees?.length === possibleCount);
  }

  const selectAllEmployees = () => {
    if (isAllEmployeeChecked()){
      setSelectedEmployees([])
    } else {
      const employees_list = dashboardReducer?.employeeList?.data || [];
      setSelectedEmployees([
        ...employees_list.filter((obj) => obj.is_active)
      ]);
    }
  }

  const toggleEmployeeSelection = (employee) => {
    if (isEmployeeChecked(employee))  {
      setSelectedEmployees(
        selectedEmployees.filter((e) => e.id !== employee.id)
      );
    } else {
      setSelectedEmployees([...selectedEmployees, employee]);
    }
  }

  useEffect(() => {
    if (id >= 0 && document.getElementById(`expand_icon_${id}`))
      document.getElementById(`expand_icon_${id}`).scrollIntoView();
  }, [id]);

  return (
    <>
      <CommonTable
        columns={[
          {
            Header: "Company Name",
            accessor: "name",
            HeaderCell: (data) => {
              return <div className={classes.headerName}>{data}</div>;
            },
          },
          {
            Header: "Total Net Salaries",
            accessor: "net_salaries",
            HeaderCell: (data) => {
              return <div className={classes.headerName}>{data}</div>;
            },
            Cell: (data, row) => {
              return (
                <div className={classes.salary}>
                  <span className="currency">{row?.currency}</span>{" "}
                  {moneyValue(data)}
                </div>
              );
            },
          },
          {
            Header: "Total Withdrawn",
            accessor: "id",
            HeaderCell: (data) => {
              return <div className={classes.headerName}>{data}</div>;
            },
            Cell: (data, row) => {
              return (
                <div className={classes.salary}>
                  <span className="currency">{row?.currency}</span>
                  {moneyValue(row?.withdrawals)}
                </div>
              );
            },
          },
          {
            Header: "Remaining Balance",
            accessor: "id",
            HeaderCell: (data) => {
              return <div className={classes.headerName}>{data}</div>;
            },
            Cell: (data, row, index) => {
              return (
                <div
                  className={classes.withdrawAction}
                  style={{ justifyContent: "space-between" }}
                >
                  <div
                    className={classes.salary}
                    style={{
                      fontFamily: theme.fontFamily.MARKPROHEAVY,
                    }}
                  >
                    <span className="currency">{row?.currency}</span>{" "}
                    {moneyValue(row?.balance)}
                  </div>
                  <div>
                    <img
                      id={`expand_icon_${index}`}
                      src="/images/chevron_dropdown_date.png"
                      alt="chevron_dropdown_date"
                      onClick={() => {
                        setId((temp) => (temp === index ? null : index));
                        employeesSetPage(1);
                        employeesSetTotalPage(1);
                      }}
                      style={{
                        transform: id === index ? "rotate(180deg)" : "",
                      }}
                    ></img>
                  </div>
                </div>
              );
            },
          },
        ]}
        data={data}
        showSubTableFor={id}
        subTableContent={() => (
          <CommonTable
            columns={[
              {
                accessor: "id",
                HeaderCell: (data) => {
                  return <div>
                    <input
                        type="checkbox"
                        checked={isAllEmployeeChecked()}
                        onChange={selectAllEmployees}
                      />
                  </div>;
                },
                Cell: (data, row) => {
                  return (
                    <div>
                      {row.is_active ? (
                        <input
                          type="checkbox"
                          checked={isEmployeeChecked(row)}
                          onChange={() => toggleEmployeeSelection(row)}
                        />
                      ): ""}
                    </div>
                  );
                },
              },
              {
                Header: "Employee Name",
                accessor: "id",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data, row) => {
                  return (
                    <div>
                      <div>
                        {row?.first_name} {row?.last_name}
                        <span className="inactive-label">
                          {row.is_active ? "" : " (Inactive)"}
                        </span>
                      </div>
                      <div className="gray-text">{row.employee_id}</div>
                    </div>
                  );
                },
              },
              {
                Header: "Total Net Salary",
                accessor: "Salary",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data, row) => {
                  return (
                    <div className={classes.salary}>
                      <span className="currency">{row?.company?.currency}</span>
                      {moneyValue(row?.profile?.salary)}
                    </div>
                  );
                },
              },
              {
                Header: "Total Withdrawn",
                accessor: "id",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data, row) => {
                  return (
                    <div className={classes.salary}>
                      <span className="currency">{row?.company?.currency}</span>
                      {moneyValue(row?.profile?.withdrawals)}
                    </div>
                  );
                },
              },
              // {
              //   Header: "Leaves",
              //   accessor: "id",
              //   HeaderCell: (data) => {
              //     return <div className={classes.headerName}>{data}</div>;
              //   },
              //   Cell: (data, row, index) => {
              //     return (
              //       <div className={classes.withdrawAction}>
              //         <div className={classes.salary}>
              //           <span className="currency">
              //             {row?.company?.currency}
              //           </span>
              //           {row?.profile?.leaves
              //             ? Math.round(row?.profile?.leaves * 1000) / 1000
              //             : 0}
              //         </div>
              //       </div>
              //     );
              //   },
              // },
              // {
              //   Header: "Deductions",
              //   accessor: "id",
              //   HeaderCell: (data) => {
              //     return <div className={classes.headerName}>{data}</div>;
              //   },
              //   Cell: (data, row, index) => {
              //     return (
              //       <div className={classes.withdrawAction}>
              //         <div className={classes.salary}>
              //           <span className="currency">
              //             {row?.company?.currency}
              //           </span>
              //           {row?.profile?.deductions
              //             ? Math.round(row?.profile?.deductions * 1000) / 1000
              //             : 0}
              //         </div>
              //       </div>
              //     );
              //   },
              // },
              {
                Header: "Remaining Balance",
                accessor: "id",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data, row, index) => {
                  return (
                    <div className={classes.withdrawAction}>
                      <div
                        className={classes.salary}
                        style={{
                          fontFamily: theme.fontFamily.MARKPROHEAVY,
                          flex: 0.5,
                        }}
                      >
                        <span className="currency">
                          {row?.company?.currency}
                        </span>
                        {moneyValue(row?.profile?.balance)}
                      </div>
                    </div>
                  );
                },
              },
              {
                accessor: "id",
                HeaderCell: (data) => {
                  return (
                    <div className={classes.withdrawAction}>
                      <CommonButton
                        type="button"
                        text="Bulk Transfer"
                        stylevariant="secondary2"
                        onClick={() => onBulkTransferCLick(selectedEmployees)}
                        disabled={!selectedEmployees.length}
                        style={{
                          flex: 0.5,
                          height: "28px",
                        }}
                        fontSize="10px"
                        fontFamily={theme.fontFamily.MARKPROBOLD}
                      />
                    </div>);
                },
                Cell: (data, row, index) => {
                  return (
                    <div className={classes.withdrawAction}>
                      <CommonButton
                        type="button"
                        text="Transfer"
                        stylevariant="secondary3"
                        onClick={() => onTransferCLick(row)}
                        disabled={!row.is_active}
                        style={{
                          flex: 0.5,
                          height: "28px",
                        }}
                        fontSize="10px"
                        fontFamily={theme.fontFamily.MARKPROBOLD}
                      />
                    </div>
                  );
                },
              },
            ]}
            data = {
              dashboardReducer?.employeeList?.data?.length
                ? dashboardReducer.employeeList.data.filter(item => item.is_active === true)
                  : []
          }

            subTable={true}
          />
        )}
        subTableContentPagination={() =>
          dashboardReducer?.employeeList?.data?.length ? (
            <div className={"pagination"} style={{ marginBottom: "1rem" }}>
              <Pagination
                page={employeesPage}
                count={employeesTotalPage}
                showFirstButton
                showLastButton
                onChange={(event, value) => {
                  employeesSetPage(value);
                }}
              />
            </div>
          ) : null
        }
      />
      {data?.length ? (
        <div className={"pagination"}>
          <Pagination
            page={page}
            count={totalPage}
            showFirstButton
            showLastButton
            onChange={(event, value) => {
              setPage(value);
              setId(null);
            }}
          />
        </div>
      ) : null}
    </>
  );
}

export default SalariesTransfersTable;
