// Dropdown.jsx

import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format, subMonths, startOfMonth, endOfMonth } from 'date-fns';
import { getReportsListAction } from "../../containers/reports/action"; // Assuming you have this action


const Dropdown = () => {
  const [months, setMonths] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [filters, setFilters] = useState({
    employeeId: '',
    startDate: null,
    endDate: null,
  });

  const dispatch = useDispatch();

  const getReportsListMethod = useCallback(
    (filters) => dispatch(getReportsListAction(filters)),
    [dispatch]
  );

  useEffect(() => {
    const currentDate = new Date();
    const previousMonth1 = format(subMonths(currentDate, 1), 'MMMM');
    const previousMonth2 = format(subMonths(currentDate, 2), 'MMMM');
    const previousMonth3 = format(subMonths(currentDate, 3), 'MMMM');


    setMonths([ previousMonth1, previousMonth2, previousMonth3]);
    getReportsListMethod(filters);

  }, [getReportsListMethod, filters]);

  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    setSelectedMonth(selectedMonth);

    // Calculate start and end dates with the current year
    const currentYear = new Date().getFullYear();
    const firstDayOfMonth = startOfMonth(new Date(`${selectedMonth} 1, ${currentYear}`));
    const lastDayOfMonth = endOfMonth(new Date(`${selectedMonth} 1, ${currentYear}`));

    // Check if the calculated dates are valid
    if (!isNaN(firstDayOfMonth) && !isNaN(lastDayOfMonth)) {
      const newFilters = {
        ...filters,
        startDate: format(firstDayOfMonth, 'yyyy-MM-dd'),
        endDate: format(lastDayOfMonth, 'yyyy-MM-dd'),
      };

      setFilters(newFilters);
    } else {
      console.error('Invalid date value');
    }
  };

  useEffect(() => {
    // Now, you can use filters.startDate and filters.endDate for API filtering
    console.log('Filters:', filters);
  }, [filters]);

  return (
    <div class="relative flex items-center space-x-4">
  <label for="month" class="text-sm">
    Select Month:
  </label>
  <select
    id="month"
    name="month"
    class="p-2 text-xs border border-gray-300 rounded"
    value={selectedMonth}
    onChange={handleMonthChange}
  >
    {months.map((month, index) => (
      <option key={index} value={month}>
        {month}
      </option>
    ))}
  </select>
</div>

  );
};

export default Dropdown;