import React, {useCallback, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import clsx from 'clsx';
import {useDispatch, useSelector} from "react-redux";
import {
  bulkSettleSalaryAction,
  getBulkMonthsToSettleAction,
  getCompanyListAction,
  getEmployeeListAction,
  getMonthsToSettleAction,
  getPaymentInfoAction,
  getPaymentListAction,
  setOvertimeRequestsFilters,
  settlePaymentAction,
  settleSalaryAction,
  setWithdrawalRequestsFilters,
} from "../../containers/dashboard/action";
import {getOvertimeListAction} from "../../containers/companyDashboard/action";
import CommonDropDown from "../dropdown";
import theme from "../../theme";
import {WithdrawApprovalStep1, WithdrawApprovalStep2,} from "../withdrawApprovalSteps";
import {SalaryTransferStep0, SalaryTransferStep1, SalaryTransferStep2,} from "../salaryTransferSteps";
import WithdrawRequestsTable from "./withdrawRequestsTable";
import SalariesTransfersTable from "./salariesTransfersTable";
import OvertimeRequestsTable from "./overtimeRequestsTable";
import TransactionsHistoryTable from "./transactionsHistoryTable";
// import TransactionsHistoryTable from "./transaction-rewritten"
import Loading from "../loading";

let companyOptions = [];

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme.colors.White,
    width: "100%",
    minHeight: "calc(100vh - 175px)",
    padding: "45px 90px",
    marginLeft: "220px",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .title": {
      color: theme.colors.Violet,
      fontFamily: theme.fontFamily.MARKPROMEDIUM,
      fontSize: "24px",
    },
    "& .filterContainer": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& .title": {
        color: theme.colors.DodgerBlueLight,
        fontFamily: theme.fontFamily.MARKPROMEDIUM,
        fontSize: "16px",
        marginRight: "15px",
      },
    },
  },
  tableTabContainer: {
    display: "flex",
    alignItems: "left",

    "& .Tab": {
      position: "relative",
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
      justifyContent: "between",
      color: "white",
      height: "100%",
      background: "#E2ECFE",
      zIndex: '0',
      borderTopLeftRadius: '8px',
      marginRight: "20px",
      filter: "drop-shadow(0px -8px 5px rgba(208, 207, 219, 0.3))",
    },
    "& .Tab:after": {
      content: '" "',
      display: 'block',
      background: '#E2ECFE',
      width: '30px',
      height: '100%',
      position: 'absolute',
      top: '0',
      right: '-18px',
      transform: 'skew(25deg)',
      borderTopRightRadius: '8px',
      zIndex: '-1',
    },
    "& .selected": {
      background: '#fff',
      zIndex: 10,
    },
    "& .selected:after": {
      background: '#fff',
    },
    "& .block": {
      display: "flex",
      fontFamily: theme.fontFamily.MARKPROMEDIUM,
      cursor: "pointer",
      padding: "15px",
      "& .label": {
        color: theme.colors.DodgerBlue,
        fontSize: "16px",
      },
      "& .count": {
        background: theme.colors.DodgerBlue,
        color: theme.colors.White,
        fontSize: "14px",
        borderRadius: "20px",
        textAlign: "center",
        height: "20px",
        marginLeft: "10px",
        lineHeight: "22px",
        padding: "0px 10px",
      },
    },
  },
  stepContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  backLink: {
    position: "absolute",
    top: 0,
    left: 0,
    color: theme.colors.DodgerBlueLight,
    fontSize: "16px",
    fontFamily: theme.fontFamily.MARKPROMEDIUM,
    cursor: "pointer",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    "& .label": {marginLeft: "5px", position: "relative", top: 2},
  },
  modalPaper: {
    position: "absolute",
  },
  extraContentContainer: {
    fontFamily: theme.fontFamily.MARKPROBOLD,
    fontSize: "12px",
    textAlign: "center",
    "& .label": {position: "relative", top: -4, marginLeft: "5px"},
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function Transactions() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getCompanyListMethod = useCallback(
    (data) => dispatch(getCompanyListAction(data)),
    [dispatch]
  );

  const getOvertimeListMethod = useCallback(
    (data) => dispatch(getOvertimeListAction(data)),
    [dispatch]
  );

  const getPaymentListMethod = useCallback(
    (data) => dispatch(getPaymentListAction(data)),
    [dispatch]
  );

  const getPaymentInfoMethod = useCallback(
    (data) => dispatch(getPaymentInfoAction(data)),
    [dispatch]
  );

  const settlePaymentMethod = useCallback(
    (data, payment_id) => dispatch(settlePaymentAction(data, payment_id)),
    [dispatch]
  );

  const settleSalaryMethod = useCallback(
    (employee_id, month) => dispatch(settleSalaryAction(employee_id, month)),
    [dispatch]
  );

  const bulkSettleSalaryMethod = useCallback(
    (employees, month) => dispatch(
      bulkSettleSalaryAction(employees.map((employee) => employee.id), month)
    ),
    [dispatch]
  );

  const getEmployeeListMethod = useCallback(
    (data) => dispatch(getEmployeeListAction(data)),
    [dispatch]
  );

  const setWithdrawalRequestsFiltersMethod = useCallback(
    (data) => dispatch(setWithdrawalRequestsFilters(data)),
    [dispatch]
  );

  const setOvertimeRequestsFiltersMethod = useCallback(
    (data) => dispatch(setOvertimeRequestsFilters(data)),
    [dispatch]
  );

  const getMonthsToSettleMethod = useCallback(
    (data) => dispatch(getMonthsToSettleAction(data)),
    [dispatch]
  );

  const getBulkMonthsToSettleMethod = useCallback(
    (country) => dispatch(getBulkMonthsToSettleAction(country)),
    [dispatch]
  );

  const dashboardReducer = useSelector((state) => {
    return state.dashboardReducer;
  });

  const companyDashboardReducer = useSelector((state) => {
    return state.companyDashboardReducer;
  });

  const loginReducer = useSelector((state) => {
    return state.loginReducer;
  });

  const [modalStyle] = useState(getModalStyle);

  const [payment_id, showPaymentId] = useState(null);
  const [employee, setEmployee] = useState(null);
  const [bulkTransferEmployees, setBulkTransferEmployees] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [salaryStep, setSalaryStep] = useState(1);
  const [table, setTable] = useState("Withdrawal Requests");
  const [selectedCompany, setSelectedCompany] = useState({
    value: 0,
    label: "All",
  });
  const [overtimeRequestsPage, setOvertimeRequestsPage] = useState(1);
  const [totalOvertimeRequestsPage, setTotalOvertimeRequestsPage] = useState(1);
  const [salariesTransfersPage, setSalariesTransfersPage] = useState(1);
  const [totalSalariesTransfersPage, setTotalSalariesTransfersPage] =
    useState(1);
  const [employeesPage, employeesSetPage] = useState(1);
  const [employeesTotalPage, employeesSetTotalPage] = useState(1);
  const [settledMonth, setSettledMonth] = useState(null);
  const [totalTransactionsHistoryPage, setTotalTransactionsHistoryPage] = useState(1);
  const [transactionsHistoryPage, setTransactionsHistoryPage] = useState(1);
  const [paymentsPage, setPaymentsPage] = useState(1);
  const [totalPaymentsPages, SetTotalPaymentsPages] = useState(1);
  const [transactionHistoryFilters, setTransactionHistoryFilters] = useState({})
  const [withdrawPage, setWithdrawPage] = useState(1)
  const [totalWithdrawPages, setTotalWithdrawPages] = useState(1)
  const [companyPage, setCompanyPage] = useState(1)
  const [totalCompanyPage, setTotalCompanyPage] = useState(1)
  const [loadingOpt, setLoadingOpt] = useState(false)

  const [id, setId] = useState(null);

  const page_size = 10;

  const getWithdrawFilters = () =>{
    let filters = {
      state: "PENDING",
      page: withdrawPage,
      page_size,
    }

    if (selectedCompany?.value) {
      filters["company_id"] = selectedCompany.value;
    }

    return filters
  }

  useEffect(() => {
    if (payment_id) getPaymentInfoMethod(payment_id);
  }, [getPaymentInfoMethod, payment_id]);

  useEffect(() => {
    let filters = {
      state: "APPROVED",
      page: overtimeRequestsPage,
      page_size,
    };
    if (selectedCompany?.value) {
      filters["company_id"] = selectedCompany.value;
    }
    getOvertimeListMethod(filters);
  }, [getOvertimeListMethod, selectedCompany, overtimeRequestsPage, page_size]);

  useEffect(() => {
    if (table === "Salaries Transfers") {
      getCompanyListMethod({page: salariesTransfersPage, page_size});
    } else if (table === "Transactions History") {
      getCompanyListMethod({page: transactionsHistoryPage, page_size});
    } else {
      getCompanyListMethod({});
    }
  }, [getCompanyListMethod, salariesTransfersPage, transactionsHistoryPage, page_size, table]);

  useEffect(() => {
    if (table === "Transactions History") {
      setTransactionHistoryFilters({
        ...transactionHistoryFilters,
        page: transactionsHistoryPage,
        company_id: dashboardReducer?.companyList?.data[id]?.id,
        history: true,
        page_size,
      })
    }
  }, [transactionsHistoryPage, page_size])

  useEffect(() => {
    if (table === "Transactions History") {
      getPaymentListMethod(transactionHistoryFilters);
    } else if (table === "Withdrawal Requests") {
      let filters = getWithdrawFilters()
      getPaymentListMethod(filters);
    } else {
      getPaymentListMethod({state: "PENDING"});
    }
  }, [getPaymentListMethod, transactionsHistoryPage, transactionHistoryFilters, page_size, table]);

  useEffect(() => {
    let filters = getWithdrawFilters()
    getPaymentListMethod(filters);
  }, [
    getPaymentListMethod,
    selectedCompany,
    withdrawPage,
    page_size,
  ]);

  useEffect(() => {
    const {overtimeList} = companyDashboardReducer;
    if (overtimeList?.count) {
      setTotalOvertimeRequestsPage(Math.ceil(overtimeList.count / page_size));
    }
  }, [companyDashboardReducer]);

  useEffect(() => {
    if (
      id >= 0 &&
      dashboardReducer?.companyList?.data?.length &&
      dashboardReducer?.companyList?.data[id] &&
      dashboardReducer?.companyList?.data[id]["id"]
    ) {
      getEmployeeListMethod({
        company_id: dashboardReducer?.companyList?.data[id]["id"],
        page: employeesPage,
        page_size,
      });
    }
  }, [id, employeesPage, getEmployeeListMethod]);

  useEffect(() => {
    if (
      id >= 0 &&
      dashboardReducer?.companyList?.data?.length &&
      dashboardReducer?.companyList?.data[id] &&
      dashboardReducer?.companyList?.data[id]["id"]
    ) {
    let filters = {state: "PENDING"};
    if (table === "Transactions History") {
      filters = transactionHistoryFilters;
    } else if (table === "Withdrawal Requests") {
      filters = getWithdrawFilters()
    }
      getPaymentListMethod(filters);
    }
  }, [id, paymentsPage, getPaymentListMethod]);

  useEffect(() => {
    const {success, paymentList, companyList, employeeList, error} =
      dashboardReducer;
    if (employeeList?.count) {
      employeesSetTotalPage(Math.ceil(employeeList.count / page_size));
    }

    if (paymentList?.count) {
      setTotalWithdrawPages(Math.ceil(paymentList.count / page_size));

      if (table === "Transactions History") {
        SetTotalPaymentsPages(Math.ceil(paymentList.count / page_size));
      }
    }

    if (companyList?.count) {
      if (table === "Salaries Transfers") {
        setTotalSalariesTransfersPage(Math.ceil(companyList.count / page_size));
      }

      if (table === "Transactions History") {
        setTotalTransactionsHistoryPage(Math.ceil(companyList.count / page_size));
      }

      if (table === "Withdrawal Requests") {
        setTotalCompanyPage(Math.ceil(companyList.count / page_size));
      }
    }

    if (error) {
      setOpenModal(false);
    }
    if (success) {
      setOpenModal(true);
      if (employee) {
        setSalaryStep(2);
        setEmployee(null);
        if (employee?.company?.id) {
          getEmployeeListMethod({
            company_id: employee.company.id,
            page: employeesPage,
            page_size,
          });
        }
        getCompanyListMethod({page: salariesTransfersPage, page_size});
      }
      if (bulkTransferEmployees?.length > 0) {
        setSalaryStep(2);
        setBulkTransferEmployees([]);
      }
      showPaymentId(null);

      let filters = {
        state: "PENDING",
        page: withdrawPage,
        page_size,
      };
      if (selectedCompany?.value) {
        filters["company_id"] = selectedCompany.value;
      }
      getPaymentListMethod(filters);
    }
  }, [
    dashboardReducer,
    selectedCompany,
    withdrawPage,
    employee,
    table,
    getPaymentListMethod,
    getEmployeeListMethod,
    getCompanyListMethod,
    salariesTransfersPage,
    transactionsHistoryPage,
  ]);

  useEffect(() => {
    if (loginReducer?.companyOptions?.length) {
      companyOptions = [];
      companyOptions.push({value: 0, label: "All"});
      for (let i = 0; i < loginReducer?.companyOptions.length; i++) {
        const each = loginReducer?.companyOptions[i];
        if (
          companyOptions.findIndex(
            (rec) => rec.value && rec.value === each.id
          ) < 0
        )
          companyOptions.push({value: each.id, label: each.name});
        if (!selectedCompany)
          setSelectedCompany({value: each.id, label: each.name});
      }
    }
  }, [loginReducer]);

  useEffect(() => {
    const {withdrawalRequestsFilters, overtimeRequestsFilters} =
      dashboardReducer;
    if (
      table === "Withdrawal Requests" &&
      withdrawalRequestsFilters?.selectedCompany?.value &&
      (!selectedCompany?.value ||
        selectedCompany.value !==
        withdrawalRequestsFilters.selectedCompany.value)
    ) {
      setSelectedCompany(withdrawalRequestsFilters.selectedCompany);
    }

    if (
      table === "Overtime Requests" &&
      overtimeRequestsFilters?.selectedCompany?.value &&
      (!selectedCompany?.value ||
        selectedCompany.value !== overtimeRequestsFilters.selectedCompany.value)
    ) {
      setSelectedCompany(overtimeRequestsFilters.selectedCompany);
    }
  }, [table, selectedCompany, dashboardReducer]);

  useEffect(() => {
    if (dashboardReducer?.error?.length) {
      const ele = document.getElementsByClassName("error-container");
      if (ele && ele.length) ele[0].scrollIntoView(false);
    }
  }, [dashboardReducer]);

  return (
    <div className={classes.root}>
      <Loading
        showLoader={
          dashboardReducer?.loading ||
          companyDashboardReducer?.loading ||
          loginReducer?.loading ||
          loadingOpt
        }
      />
      <div className={classes.headerContainer}>
        <div className="title">Transactions</div>
        {!payment_id &&
          (table === "Withdrawal Requests" ||
            table === "Overtime Requests") && (
            <div className="filterContainer">
              <div className="title">Filter by</div>
              <div style={{width: "200px"}}>
                <CommonDropDown
                  placeholder={"Select Company"}
                  options={companyOptions}
                  value={selectedCompany}
                  onChange={(value) => {
                    setSelectedCompany(value);
                    if (table === "Withdrawal Requests") {
                      setWithdrawalRequestsFiltersMethod({
                        selectedCompany: value,
                      });
                    }
                    if (table === "Overtime Requests") {
                      setOvertimeRequestsFiltersMethod({
                        selectedCompany: value,
                      });
                    }
                  }}
                />
              </div>
            </div>
          )}
      </div>
      {dashboardReducer?.error?.length ? (
        <div className={"error-container"}>
          {dashboardReducer.error.map((each, index) => (
            <div key={index}>{each}</div>
          ))}
        </div>
      ) : null}
      {!(dashboardReducer?.loading || companyDashboardReducer?.loading) ? (
        <>
          <div
            style={{
              marginTop: payment_id ? "25px" : "50px",
            }}
          >
            {!payment_id && (
              <div className={classes.tableTabContainer}>
                <div
                  className={clsx("Tab", table === 'Withdrawal Requests' ? 'selected' : '')}
                  onClick={() => {
                    setTable("Withdrawal Requests");
                  }}
                >
                  <div
                    className="block"
                    onClick={() => {
                      setTable("Withdrawal Requests");
                    }}
                  >
                    <div className="label">Withdrawal Requests</div>
                    {dashboardReducer?.paymentList?.count ? (
                      <div className="count">
                        {dashboardReducer?.paymentList?.count}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div
                  className={clsx("Tab", table === 'Overtime Requests' ? 'selected' : '')}
                  onClick={() => {
                    setTable("Overtime Requests");
                  }}
                >
                  <div
                    className="block"
                    onClick={() => {
                      setTable("Overtime Requests");
                    }}
                  >
                    <div className="label">Overtime Requests</div>
                    {companyDashboardReducer?.overtimeList?.count ? (
                      <div className="count">
                        {companyDashboardReducer.overtimeList.count}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div
                  className={clsx("Tab", table === 'Salaries Transfers' ? 'selected' : '')}
                  onClick={() => {
                    setTable("Salaries Transfers");
                  }}
                >
                  <div
                    className="block"
                    onClick={() => {
                      setTable("Salaries Transfers");
                    }}
                  >
                    <div className="label">Salaries Transfers</div>
                    {dashboardReducer?.companyList?.count ? (
                      <div className="count">
                        {dashboardReducer?.companyList?.count}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div
                  className={clsx("Tab", table === 'Transactions History' ? 'selected' : '')}
                  onClick={() => {
                    setTable("Transactions History");
                  }}
                >
                  <div
                    className="block"
                    onClick={() => {
                      setTable("Transactions History");
                    }}
                  >
                    <div className="label">Transactions History</div>
                    {dashboardReducer?.paymentSubTableList?.count ? (
                      <div className="count">
                        {dashboardReducer?.paymentSubTableList?.count}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div>
            {payment_id && table === "Withdrawal Requests" ? (
              <div className={classes.stepContainer}>
                <div
                  className={classes.backLink}
                  onClick={() => {
                    showPaymentId(null);
                  }}
                >
                  <img src="/images/right-arrow.png" alt="right-arrow"/>
                  <span className="label">Back</span>
                </div>
                <WithdrawApprovalStep1
                  onSubmit={(receipt) => {
                    settlePaymentMethod({receipt}, payment_id);
                  }}
                  // payment_id={payment_id}
                  showErrors={false}
                />
              </div>
            ) : table === "Withdrawal Requests" ? (
              <WithdrawRequestsTable
                id={id}
                setId={setId}
                data={dashboardReducer?.companyList?.data}
                onConfirmWithdrawClick={(id) => {
                  showPaymentId(id);
                }}
                setSelectedCompany={setSelectedCompany}
                companyPage={companyPage}
                setCompanyPage={setCompanyPage}
                totalCompanyPage={totalCompanyPage}

                totalWithdrawPages={totalWithdrawPages}
                setTotalWithdrawPages={setTotalWithdrawPages}
                setWithdrawPage={setWithdrawPage}
                withdrawPage={withdrawPage}
              />
            ) : table === "Overtime Requests" ? (
              <OvertimeRequestsTable
                data={companyDashboardReducer?.overtimeList?.data}
                page={overtimeRequestsPage}
                setPage={setOvertimeRequestsPage}
                totalPage={totalOvertimeRequestsPage}
              />
            ) : table === "Salaries Transfers" ? (
              <SalariesTransfersTable
                id={id}
                setId={setId}
                onTransferCLick={(data) => {
                  setEmployee(data);
                  getMonthsToSettleMethod(data?.id)
                  setOpenModal(true);
                  setSalaryStep(1);
                }}
                onBulkTransferCLick={(employees) => {
                  setBulkTransferEmployees(employees)
                  getBulkMonthsToSettleMethod(employees.map(employee => employee.company)[0].country)
                  setOpenModal(true);
                  setSalaryStep(0);
                }}
                data={dashboardReducer?.companyList?.data}
                getEmployeeListMethod={getEmployeeListMethod}
                page={salariesTransfersPage}
                setPage={setSalariesTransfersPage}
                totalPage={totalSalariesTransfersPage}
                employeesPage={employeesPage}
                employeesSetPage={employeesSetPage}
                employeesTotalPage={employeesTotalPage}
                employeesSetTotalPage={employeesSetTotalPage}
              />
            ) : table === "Transactions History" ? (
              <TransactionsHistoryTable
                id={id}
                setId={setId}
                data={dashboardReducer?.companyList?.data}
                transactionHistoryFilters={transactionHistoryFilters}
                setTransactionHistoryFilters={setTransactionHistoryFilters}

                page={transactionsHistoryPage}
                setPage={setTransactionsHistoryPage}
                totalPage={totalTransactionsHistoryPage}

                paymentsPage={paymentsPage}
                setPaymentsPage={setPaymentsPage}
                totalPaymentsPages={totalPaymentsPages}
                SetTotalPaymentsPages={SetTotalPaymentsPages}
                setLoadingOpt = {setLoadingOpt}
              />
            ) : null}

            <Modal open={openModal} onClose={() => setOpenModal(false)}>
              <div style={modalStyle} className={classes.modalPaper}>
                {table === "Withdrawal Requests" ? (
                  <WithdrawApprovalStep2
                    onSubmit={() => {
                      setOpenModal(false);
                    }}
                    extraContent={
                      <div className={classes.extraContentContainer}>
                        <img
                          src="/images/exclamation-mark.png"
                          alt="exclamation-mark"
                        />
                        <span className="label">
                          All withdrawal requests are available in employee
                          records
                        </span>
                      </div>
                    }
                  />
                ) : salaryStep === 0 ? (
                  <SalaryTransferStep0
                    employees={bulkTransferEmployees}
                    datesToSettle={dashboardReducer?.bulkMonthsToSettle?.data?.dates_to_settle}
                    onSubmit={(selectedMonth) => {
                      setSettledMonth(selectedMonth);
                      bulkSettleSalaryMethod(bulkTransferEmployees, selectedMonth);
                    }}
                    onCancel={() => {
                      setOpenModal(false);
                    }}
                  />
                ) : salaryStep === 1 ? (
                  <SalaryTransferStep1
                    employee={employee}
                    onSubmit={(selectedMonth) => {
                      setSettledMonth(selectedMonth);
                      settleSalaryMethod(employee?.id, selectedMonth);
                    }}
                    datesToSettle={dashboardReducer?.monthsToSettle?.data?.dates_to_settle}
                    onCancel={() => {
                      setOpenModal(false);
                    }}
                  />
                ) : (
                  <SalaryTransferStep2
                    settledMonth={settledMonth}
                    onSubmit={() => {
                      setSalaryStep(1);
                      setOpenModal(false);
                      setSettledMonth(null);
                    }}
                    extraContent={
                      <div className={classes.extraContentContainer}>
                        <img
                          src="/images/exclamation-mark.png"
                          alt="exclamation-mark"
                        />
                        <span className="label">
                          All company transfer history are available in reports
                        </span>
                      </div>
                    }
                  />
                )}
              </div>
            </Modal>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default Transactions;
